import FeatureCard, {
	FeatureCardLayout,
} from "@components/FeatureCard/FeatureCard";
import FeatureSummary from "@components/FeatureSummary/FeatureSummary";
import { PageLayout } from "@components/index";
import Margins from "@components/Margins/Margins";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import IntegrationsHero from "./_components/IntegrationsHero";
import * as styles from "./Integrations.module.scss";
import { JoinInstitutionsV2 } from "pages/landingpagev2/_components/_JoinInstitutionsV2/_JoinInstitutionsV2";

const Integrations = () => {
	return (
		<PageLayout
			seoOptions={{ type: "PREDEFINED", pageName: "integrations" }}
		>
			<Margins>
				<IntegrationsHero />
				<FeatureCardLayout className={styles.cardLayout}>
					<FeatureCard
						title="Strategic Approach to Customization"
						list={[
							"We understand that every research team has its own distinct processes, and we prioritize understanding your particular needs.",
							"We plan and strategize to make sure that your process integrates into the Genemod platform without any snags.",
							"We’re committed to giving you a solution that aligns with both your operational requirements and your research goals.",
						]}
						image={
							<StaticImage
								src="./_assets/integrations-img-1.png"
								alt="Strategic Approach to Customization"
							/>
						}
					/>
				</FeatureCardLayout>
			</Margins>
			<FeatureSummary
				title="Easy Collaboration with Top Cloud Services"
				list={[
					"Genemod proudly collaborates with leading platforms such as Google Drive and Microsoft Office 356 so you have the best user experience possible.",
					"View your files directly within Genemod, making implementation and transition phases simpler.",
					"Our integration ensures that your research documentation remains accessible and organized, regardless of its source.",
				]}
				img={
					<StaticImage
						src="./_assets/integrations-img-2.png"
						alt="Easy Collaboration with Top Cloud Services"
					/>
				}
			/>
			<Margins className={styles.secondMargin}>
				<FeatureCard
					title="Exclusive Access to Our Public API "
					list={[
						"A select group of customers has exclusive access to Genemod’s robust Public API.",
						"If you’re interested in optimizing your operational efficiency even further, our team is ready to discuss potential API integrations.",
						"Reach out to us to explore how we can help you drive innovation and streamline your research workflow with our Public API.",
					]}
					image={
						<StaticImage
							src="./_assets/integrations-img-3.png"
							alt="Strategic Approach to Customization"
						/>
					}
				/>
				<JoinInstitutionsV2 outerContainerClassName={styles.joinInstitutions} />
			</Margins>
		</PageLayout>
	);
};

export default Integrations;
