// extracted by mini-css-extract-plugin
export var container = "FeatureSummary-module--container--6lz1f";
export var content = "FeatureSummary-module--content--Lmjad";
export var contentLeft = "FeatureSummary-module--contentLeft--P4zhy";
export var contentRight = "FeatureSummary-module--contentRight--WwPRD";
export var item = "FeatureSummary-module--item--CckUR";
export var itemText = "FeatureSummary-module--itemText--5AVQS";
export var limitWidthOnLargeScreens = "FeatureSummary-module--limitWidthOnLargeScreens--JgpZa";
export var list = "FeatureSummary-module--list--vpFMx";
export var main = "FeatureSummary-module--main--0wKWH";
export var responsiveImage = "FeatureSummary-module--responsiveImage--sLl2M";
export var star = "FeatureSummary-module--star--3OFkj";
export var title = "FeatureSummary-module--title--5kBGY";