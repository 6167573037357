import { StarPattern } from "@assets/icons";
import React from "react";
import { TypographyV2 } from "..";
import * as styles from "./FeatureSummary.module.scss";
import classNames from "classnames";

type Props = {
	img: JSX.Element;
	title: string;
	subtitle?: string;
	list: string[];
	sublist?: string[];
	imageRight?: boolean;
	imageLeft?: boolean;
};

const FeatureSummary = ({ img, title, subtitle ,list, sublist, imageRight, imageLeft }: Props) => {
	let contentClassName = styles.content;

	if(imageRight) {
		contentClassName = classNames(contentClassName, styles.contentRight);
	}

	if(imageLeft) {
		contentClassName = classNames(contentClassName, styles.contentLeft);
	}

	return (
		<div className={styles.container}>
		<div className={styles.main}>
			<div className={styles.responsiveImage}>
				{img}
			</div>
			<div className={contentClassName}>
				<TypographyV2
          			className={styles.title}
					variant="HEADING_2"
					tabletVariant="HEADING_3"
					mobileVariant="HEADING_4"
					weight="MEDIUM"
					color="white"
				>
					{title}
				</TypographyV2>
				{subtitle && (
					<TypographyV2
						className={styles.title}
						variant="BODY_TEXT_EXTRA_LARGE"
						tabletVariant="BODY_TEXT_LARGE"
						mobileVariant="BODY_TEXT_MEDIUM"
						weight="REGULAR"
						color="white"
					>
						{subtitle}
					</TypographyV2>
				)}
				<div className={styles.list}>
					{list.map((obj, index) => {
						return (
							<div key={index} className={styles.item}>
								<div className={styles.star}>
									<StarPattern />
								</div>
								<div className={styles.itemText}>
								<TypographyV2
									variant="BODY_TEXT_LARGE"
									color="white"
									weight={sublist ? "MEDIUM" : "REGULAR"}
								>
									{obj}
								</TypographyV2>
									{sublist && sublist.length > 0 && sublist[index] && (
										<TypographyV2
											variant="BODY_TEXT_LARGE"
											weight="REGULAR"
											mobileVariant="BODY_TEXT_LARGE"
											color="white"
										>
											{sublist[index]}
										</TypographyV2>
									)}
                                </div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
		</div>
	);
};

export default FeatureSummary;
